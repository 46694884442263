// src/components/SearchBar.js
import React, { useState } from 'react';

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <form onSubmit={handleSearch} className="mb-4">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search for products..."
        className="border p-2 w-full rounded-full focus:outline-none focus:ring-2 focus:ring-[#50cc14]"
      />
      <button
        type="submit"
        className="bg-blue-500 text-white p-2 mt-2 rounded-full transition-colors duration-300 ease-in-out hover:bg-[#50cc14] hover:text-white"
      >
        Search
      </button>
    </form>
  );
}

export default SearchBar;
