import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaUserSecret, FaUser } from 'react-icons/fa';
import { MdDashboard, MdFolderDelete } from 'react-icons/md';
import { IoSettings, IoHome } from 'react-icons/io5';
import axios from 'axios';
import { BaseUrl } from '../../Config/config';
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleRFill } from "react-icons/ri";

function AdminDashboard() {
  const [totalUsers, setTotalUsers] = useState(0);
  const [loggedInUsers, setLoggedInUsers] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [UserName, setUserName] = useState('')
  const [Email, setEmail] = useState('')
  const token = localStorage.getItem('token') || '';
  const [confirmlogout, setconfirmlogout] = useState(false)




  const navigate = useNavigate();

  useEffect(() => {
    const tokenadmin = localStorage.getItem('tokenadmin');
    if (!tokenadmin) {
      navigate('/AdminLogin');
    }
  }, [navigate]);

  useEffect(() => {
    const storedUsers = localStorage.getItem('totalUsers');
    if (storedUsers) {
      setTotalUsers(parseInt(storedUsers, 10));
    }

    const storedDeletedCount = localStorage.getItem('deletedCount');
    if (storedDeletedCount) {
      setDeletedCount(parseInt(storedDeletedCount, 10));
    }
  }, []);

  useEffect(() => {
    const fetchData = () => {
      const savedToken = localStorage.getItem('tokenadmin');
      console.log("savedToken", savedToken);
      
      axios.post(BaseUrl + 'api/my-profile', {}, {   
        headers: {
          Authorization: `Bearer ${savedToken}`,
        },
      })
      .then((response) => {
        console.log(response.data.user);
        setUserName(response.data.user.name);
        setEmail(response.data.user.email);
      })
      .catch((err) => {
        console.log(err);
        navigate('/adminlogin');
      });
    };
  
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 5000); // Fetch every 5 seconds
    
    return () => clearInterval(interval); // Clean up on component unmount
  }, []); 
  

  useEffect(() => {
    console.log("username",UserName,"email",Email);
    
  }, [UserName,Email])
  
  

  useEffect(() => {
    if (token) {
      fetchLoggedInUsers(token);
    }
  }, [token]);

  const fetchLoggedInUsers = async (token) => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/User/GetUserDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('API Response:', response.data);
      setLoggedInUsers(response.data.loggedInUsers);
    } catch (error) {
      console.error('Error fetching logged-in user count:', error);
    }
  };

  const handleLogin = () => {
    if (loginId === 'kiran' && password === '12345') {
      localStorage.setItem('tokenadmin', 'dummy-token'); // Replace with actual token handling
      navigate('/adminDashboard');
    } else {
      setError('Invalid ID or password');
    }
  };


  const handleLogout =()=>{
    const savedToken = localStorage.getItem('tokenadmin');
    axios.post(BaseUrl + 'api/logout', {}, {   
      headers: {
        Authorization: `Bearer ${savedToken}`,
      },
    })
    .then((response)=>{
      localStorage.removeItem('tokenadmin'); // Clear token
      window.location.href = '/adminlogin'; // R
    })
     .catch((err)=>{
      console.log(err);
      
     })
    
    
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col lg:flex-row">
      {/* Sidebar */}
      <div className="bg-gray-800 text-white h-64 lg:h-screen w-full lg:w-64 flex flex-col justify-between">
        <div className="p-4">
          <h1 className="text-2xl font-semibold mb-4">Admin Dashboard</h1>
          <ul className="space-y-2">
            <div className="flex items-center">
              <MdDashboard className="text-gray-400 mr-3 h-5 w-5" />
              <li>
                <NavLink exact to="/Home1" activeClassName="bg-gray-700 rounded" className="block py-2 px-4 hover:bg-gray-700 rounded">Dashboard</NavLink>
              </li>
            </div>
            <div className='flex items-center'>
              <FaUser className='text-gray-400 mr-3 h-5 w-5' />
              <li>
                <NavLink to="/dashboardUserSignInTable" activeClassName="bg-gray-700 rounded" className="block py-2 px-4 hover:bg-gray-700 rounded">Users</NavLink>
              </li>
            </div>
            <div className='flex items-center'>
              <IoSettings className='text-gray-400 mr-3 h-5 w-5' />
              <li>
                <NavLink to="/a" activeClassName="bg-gray-700 rounded" className="block py-2 px-4 hover:bg-gray-700 rounded">Settings</NavLink>
              </li>
            </div>
            {/* admin setting */}
            
            <div className='flex items-center'>
              <IoSettings className='text-gray-400 mr-3 h-5 w-5' />
              <li>
                <NavLink to="/AdminSettings" activeClassName="bg-gray-700 rounded" className="block py-2 px-4 hover:bg-gray-700 rounded">Admin Settings</NavLink>
              </li>
            </div>





















            
            <div className='flex items-center'>
              <IoHome className='text-gray-400 mr-3 h-5 w-5' />
              <li>
                <NavLink to="/" activeClassName="bg-gray-700 rounded" className="block py-2 px-4 hover:bg-gray-700 rounded">Go to home</NavLink>
              </li>
            </div>
            <div className='flex items-center'>
              <RiLogoutCircleRFill className='text-gray-400 mr-3 h-5 w-5' />
              <li>
                <button onClick={()=>{setconfirmlogout(true)}} className="block py-2 px-4 hover:bg-gray-700 rounded">Logout</button>
              </li>
            </div>
          </ul>
        </div>
        <div className="p-4 flex border-t border-gray-700">
  <div className='w-1/5'><CgProfile size={28} /></div>
  <div className='w-4/5'>
    <div><b>{UserName ?? UserName}</b></div>
    <div className='truncate overflow-hidden text-ellipsis whitespace-nowrap'>{Email ?? Email}</div>
  </div>
</div>

      </div>
      {/* Main Content */}
      <div className="flex-grow p-6 lg:p-10">
        <h1 className="text-2xl lg:text-3xl font-semibold mb-4 lg:mb-6">Welcome to Admin Dashboard</h1>

        {/* Login Form */}
        <div className="bg-white rounded-lg p-6 shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">Admin Login</h2>
          <input
            type="text"
            placeholder="User ID"
            value={loginId}
            onChange={(e) => setLoginId(e.target.value)}
            className="block w-full p-2 mb-4 border border-gray-300 rounded"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full p-2 mb-4 border border-gray-300 rounded"
          />
          <button
            onClick={handleLogin}
            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          >
            Login
          </button>
          {error && <p className="mt-4 text-red-500">{error}</p>}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6">
          {/* Card for Total Users */}
          <div className="bg-white rounded-lg p-4 lg:p-6 shadow-md flex items-center">
            <FaUserSecret className="text-black-500 mr-4 lg:mr-7 h-8 w-8 lg:h-10 lg:w-12" />
            <div>
              <h2 className="text-lg lg:text-xl font-semibold mb-1">Total Users</h2>
              <p className="text-3xl lg:text-4xl font-bold">{totalUsers}</p>
            </div>
          </div>
          {/* Card for Deleted Items Count */}
          <div className="bg-white rounded-lg p-4 lg:p-6 shadow-md flex items-center">
            <MdFolderDelete className="text-black-500 mr-4 lg:mr-7 h-8 w-8 lg:h-10 lg:w-12" />
            <div>
              <h2 className="text-lg lg:text-xl font-semibold mb-1">Deleted Items</h2>
              <p className="text-3xl lg:text-4xl font-bold">{deletedCount}</p>
            </div>
          </div>
        </div>
      </div>
      {confirmlogout && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-1/3">
            <h3 className="text-lg font-semibold mb-4">Confirm Logout</h3>
            <p className="mb-6">Are you sure you want to log out?</p>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                onClick={() => setconfirmlogout(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
