import React, { useState } from "react";
import axios from "axios";
import MainModal from "./MainModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../Config/config";

function Login({ modalOpen, setModalOpen }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [newPassword, setNewPassword] = useState(""); // New password field
  const [confirmNewPassword, setConfirmNewPassword] = useState(""); // Confirm password field
  const navigate = useNavigate();

  const handleLoginSuccess = (token) => {
    localStorage.setItem("token", token);

    toast.success("Login successful!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      className: "text-sm",
    });

    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BaseUrl}api/login`,
        { email, password },
        { headers: { Accept: "application/json" } }
      );

      if (response.status !== 200) {
        throw new Error("Login failed");
      }

      const token = response.data.token;
      if (token) {
        setModalOpen(false);
        handleLoginSuccess(token);
      } else {
        throw new Error("Token not received");
      }
    } catch (err) {
      console.error("Login error:", err);
      setError(err.response?.data?.message || err.message);
    } finally {
      setEmail("");
      setPassword("");
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        `${BaseUrl}api/forget-password`,
        { email: forgotPasswordEmail },
        { headers: { Accept: "application/json" } }
      );
      toast.success("OTP sent to your email!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      setForgotPasswordModalOpen(false);
      setOtpModalOpen(true);
    } catch (err) {
      console.error("Forgot password error:", err);
      setError(err.response?.data?.message || err.message);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    try {
      if (newPassword !== confirmNewPassword) {
        setOtpError("Passwords do not match");
        return;
      }

      // API call to reset password using reset-password endpoint
      await axios.post(
        `${BaseUrl}api/reset-password`,  // updated endpoint
        {
          email: forgotPasswordEmail,
          otp,
          new_password: newPassword,
          new_password_confirmation: confirmNewPassword,
        },
        { headers: { Accept: "application/json" } }
      );

      toast.success("Password updated successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });

      // Redirect to home page after password reset
      setOtpModalOpen(false);
      navigate("/"); // This will navigate to the home page
    } catch (err) {
      console.error("OTP verification error:", err);
      setOtpError(err.response?.data?.message || err.message);
    }
  };

  return (
    <div>
      <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div className="inline-block sm:w-4/5 md:w-3/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full relative rounded-2xl bg-gradient-to-r from-green-100 via-white to-green-100">
          <h2 className="text-3xl font-bold text-green-800">Login</h2>
          <form className="flex flex-col gap-6 text-left" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="EMAIL ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="h-12 w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
            />
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="h-12 w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 px-3 flex items-center"
              onClick={() => setShowPassword(!showPassword)}
            >
              <svg
                className={`w-5 h-5 ${showPassword ? 'text-green-500' : 'text-gray-500'}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={showPassword ? 'M12 8.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7z' : 'M12 4.5a7.5 7.5 0 11-7.5 7.5 7.5 7.5 0 017.5-7.5zm0 15a7.5 7.5 0 01-7.5-7.5 7.5 7.5 0 01-7.5-7.5z'}
                />
              </svg>
            </button>
            <button
              type="submit"
              className="bg-green-800 text-white hover:bg-green-700 transition duration-300 ease-in-out lg:py-3 py-2 px-6 font-semibold rounded-md text-xs lg:text-sm shadow-lg transform hover:scale-105"
            >
              Login
            </button>
            {error && <p className="text-red-500">{error}</p>}
            <p className="text-center mt-2 text-green-800">
              Don't have an account?{" "}
              <Link to="/signin" className="text-green-800 underline">
                Sign up
              </Link>
            </p>
            <p className="text-center mt-2 text-green-800 cursor-pointer underline" onClick={() => setForgotPasswordModalOpen(true)}>
              Forgot Password?
            </p>
          </form>
        </div>
      </MainModal>

      {forgotPasswordModalOpen && (
        <MainModal modalOpen={forgotPasswordModalOpen} setModalOpen={setForgotPasswordModalOpen}>
          <div className="inline-block sm:w-4/5 md:w-3/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full relative rounded-2xl bg-gradient-to-r from-green-100 via-white to-green-100">
            <h2 className="text-3xl font-bold text-green-800">Forgot Password</h2>
            <form className="flex flex-col gap-6 text-left" onSubmit={handleForgotPassword}>
              <input
                type="email"
                placeholder="Enter your email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                required
                className="h-12 w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
              />
              <button
                type="submit"
                className="bg-green-800 text-white hover:bg-green-700 transition duration-300 ease-in-out lg:py-3 py-2 px-6 font-semibold rounded-md text-xs lg:text-sm shadow-lg transform hover:scale-105"
              >
                Send OTP
              </button>
              {error && <p className="text-red-500">{error}</p>}
            </form>
          </div>
        </MainModal>
      )}

      {otpModalOpen && (
        <MainModal modalOpen={otpModalOpen} setModalOpen={setOtpModalOpen}>
          <div className="inline-block sm:w-4/5 md:w-3/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full relative rounded-2xl bg-gradient-to-r from-green-100 via-white to-green-100">
            <h2 className="text-3xl font-bold text-green-800">Reset Password</h2>
            <form className="flex flex-col gap-6 text-left" onSubmit={handleOtpSubmit}>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                className="h-12 w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
              />
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="h-12 w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
                className="h-12 w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
              />
              <button
                type="submit"
                className="bg-green-800 text-white hover:bg-green-700 transition duration-300 ease-in-out lg:py-3 py-2 px-6 font-semibold rounded-md text-xs lg:text-sm shadow-lg transform hover:scale-105"
              >
                Reset Password
              </button>
              {otpError && <p className="text-red-500">{otpError}</p>}
            </form>
          </div>
        </MainModal>
      )}

      <ToastContainer />
    </div>
  );
}

export default Login;
