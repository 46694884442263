import React from 'react';
import Layout from '../layout/Layout';
import CountUp from 'react-countup';
import aboutttImg from '../images/nnnnnnnnnnnnnsshdidh.jpg';

function AboutUs() {
  return (
    <Layout>
      {/* Main container with green background and padding */}
      <div className="min-h-screen bg-green-900 py-12 px-6 flex items-center justify-center">
        <div className="container mx-auto max-w-6xl">
          {/* Content container with padding and no background color */}
          <div className="rounded-lg p-8 w-full max-w-6xl animate-fade-in-up">
            {/* Heading Section */}
            <div className="flex flex-col gap-6 xl:py-10 py-6 px-4">
              {/* Main Heading with animation */}
              <div className="text-center mb-4">
                <h1 className="text-5xl font-extrabold bg-gradient-to-r from-[#d4af37] to-[#004a00] bg-clip-text text-transparent uppercase animate-bounce">
                  <span className="text-yellow-400">WHAT WE DO</span> <br />
                  <span className="text-[#4ade80]">WHO WE ARE</span>
                </h1>
              </div>

              {/* Image Section with fade-in effect */}
              <div className="relative w-full overflow-hidden rounded-lg mb-4 animate-fade-in">
                <img
                  className="w-full h-auto rounded-lg shadow-lg object-cover"
                  src={aboutttImg}
                  alt="About Us"
                />
                <div className="absolute inset-0 bg-black bg-opacity-25 rounded-lg"></div>
              </div>

              {/* Text Section with slide-in effect */}
              <div className="flex flex-col items-center text-center animate-slide-in">
                <h3 className="text-4xl mb-4 font-bold text-yellow-400 bg-gradient-to-r from-yellow-300 to-yellow-500 text-transparent bg-clip-text animate-pulse">
                  WELCOME TO OUR ECOMMERSIO
                </h3>
                <div className="mt-4 text-base leading-7 text-yellow-400 max-w-4xl">
                  <p className="mb-4">
                    Holistically seize parallel metrics and functional ROI. Seamlessly revolutionize error-free internal or "organic" sources before effective scenarios. Progressively incentivize state-of-the-art applications for efficient intellectual capital. Credibly leverage existing distinctive mindshare through cutting-edge schemas.
                  </p>
                  <p className="mb-4">
                    Dynamically embrace diverse customer service and installed base paradigms. Credibly seize enterprise-wide experiences for end-to-end data. Professionally brand flexible alignments and cost-effective architectures. Enthusiastically incentivize seamless communities with revolutionary metrics.
                  </p>
                </div>
              </div>

              {/* Cards Section with hover scale effect */}
              <div className="grid md:grid-cols-2 gap-6">
              <div className="p-6 shadow-md rounded-lg flex flex-col justify-between transition-transform duration-500 ease-in-out bg-gradient-to-br from-[#fbbf24] via-[#f59e0b] to-[#f97316] hover:scale-105 hover:shadow-2xl animate-fade-in">
  <div>
    <span className="text-4xl block font-extrabold mb-2 text-[#d1fae5]">
      <CountUp end={10000} duration={2.5} />
    </span>
    <h4 className="text-xl font-bold mb-1 text-[#d1fae5]">LISTED PRODUCTS</h4>
    <p className="mb-0 leading-7 text-[#d1fae5]">
      Dynamically morph team-driven partnerships after vertical.
    </p>
  </div>
</div>

                <div className="p-6 shadow-md rounded-lg flex flex-col justify-between transition-transform duration-500 ease-in-out bg-gradient-to-br from-[#16a34a] via-[#22c55e] to-[#4ade80] hover:scale-105 hover:shadow-2xl animate-fade-in">
                  <div>
                    <span className="text-4xl block font-extrabold mb-2 text-yellow-400">
                      <CountUp end={8000} duration={2.5} />
                    </span>
                    <h4 className="text-xl font-bold mb-1 text-yellow-400">LOVELY CUSTOMERS</h4>
                    <p className="mb-0 leading-7 text-yellow-400">
                      Competently productize virtual models without performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Decorative Shapes */}
          <div className="absolute inset-0 pointer-events-none overflow-hidden">
            <svg className="absolute top-0 left-0 transform translate-x-16 -translate-y-20" width="300" height="300" fill="none">
              <circle cx="150" cy="150" r="150" fill="#A2CA71" fillOpacity="0.2" />
            </svg>
            <svg className="absolute bottom-0 right-0 transform -translate-x-20 translate-y-24" width="400" height="400" fill="none">
              <circle cx="200" cy="200" r="200" fill="#387F39" fillOpacity="0.15" />
            </svg>
          </div>
        </div>
      </div>

      {/* Custom CSS for animations */}
      <style jsx>{`
        @keyframes fade-in-up {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes slide-in {
          from {
            opacity: 0;
            transform: translateX(-50px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        .animate-fade-in-up {
          animation: fade-in-up 0.5s ease-in-out forwards;
        }

        .animate-fade-in {
          animation: fade-in 0.7s ease-in-out forwards;
        }

        .animate-slide-in {
          animation: slide-in 0.7s ease-in-out forwards;
        }
      `}</style>
    </Layout>
  );
}

export default AboutUs;
