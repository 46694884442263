import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdDeleteForever } from "react-icons/md";
import { BaseUrl } from '../../Config/config';
import { useNavigate } from 'react-router-dom';

function DashboardProducts() {
  
  const navigate = useNavigate();

  useEffect(() => {
    const tokenadmin = localStorage.getItem('tokenadmin');
    if (!tokenadmin) {
      navigate('/AdminLogin');
    }
  }, [navigate]);

  const [tableData, setTableData] = useState([]);
  const [newRowItemType, setNewRowItemType] = useState('');
  const [newRowCode, setNewRowCode] = useState('');

  // Fetch data from API on component mount
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('tokenadmin');
      try {
        const response = await fetch(BaseUrl + 'api/item-types', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setTableData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Submit new row and POST to API
  const handleNewRowSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('tokenadmin');

    const newItem = {
      item_type: newRowItemType,
      code: newRowCode
    };

    try {
      const response = await fetch(BaseUrl + 'api/item-types', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newItem),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success('Item added successfully');

      // Fetch the updated list of items after adding
      const fetchResponse = await fetch(BaseUrl + 'api/item-types', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!fetchResponse.ok) {
        throw new Error('Failed to fetch updated items');
      }

      const result = await fetchResponse.json();
      setTableData(result);
      setNewRowItemType('');
      setNewRowCode('');
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('ITEM TYPE ALREADY ADDED');
    }
  };

  // Handle editing a row and updating it using POST
  const handleEditRow = async (id, field, value) => {
    const token = localStorage.getItem('tokenadmin');

    const updatedItem = tableData.find(row => row.id === id);
    updatedItem[field] = value;

    try {
      const response = await fetch(BaseUrl + `api/item-types/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedItem),
      });

      if (!response.ok) {
        throw new Error('Failed to update item');
      }

      const updatedData = tableData.map(row =>
        row.id === id ? { ...row, [field]: value } : row
      );
      setTableData(updatedData);
      toast.success('Item updated successfully');
    } catch (error) {
      console.error('Error updating item:', error);
      toast.error('Failed to update item');
    }
  };

  // Handle deleting a row using DELETE
  const handleDeleteRow = async (id) => {
    const token = localStorage.getItem('tokenadmin');

    if (!id) {
      toast.error('Invalid item ID');
      return;
    }

    try {
      const response = await fetch(BaseUrl + `api/item-types/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'Failed to delete item');
      }

      toast.success('Row deleted successfully');

      // Fetch the updated list of items
      const fetchResponse = await fetch(BaseUrl + 'api/item-types', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!fetchResponse.ok) {
        throw new Error('Failed to fetch updated items');
      }

      const result = await fetchResponse.json();
      setTableData(result);
    } catch (error) {
      console.error('Error deleting row:', error);
      toast.error('Failed to delete item: ' + error.message);
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <ToastContainer />
      <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Admin Page</h1>

      <form className="bg-white p-6 rounded-lg shadow-lg" onSubmit={handleNewRowSubmit}>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Item Type"
            value={newRowItemType}
            onChange={(e) => setNewRowItemType(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-600"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Code"
            value={newRowCode}
            onChange={(e) => setNewRowCode(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-600"
          />
        </div>
        <button
          type="submit"
          className="bg-gray-600 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
        >
          Submit
        </button>
      </form>

      <table className="w-full border-collapse border border-gray-300 mt-6 bg-white rounded-lg shadow-lg">
        <thead>
          <tr className="bg-gray-600 text-white">
            <th className="px-4 py-2">ID</th>
            <th className="px-4 py-2">Item Type</th>
            <th className="px-4 py-2">Code</th>
            <th className="px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <tr key={row.id} className="odd:bg-gray-100 even:bg-gray-200 text-gray-700">
              <td className="border border-gray-300 py-2 px-4">{row.id}</td>
              <td className="border border-gray-300 py-2 px-4">
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-600"
                  value={row.item_type}
                  onChange={(e) => handleEditRow(row.id, 'item_type', e.target.value)}
                />
              </td>
              <td className="border border-gray-300 py-2 px-4">
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-600"
                  value={row.code}
                  onChange={(e) => handleEditRow(row.id, 'code', e.target.value)}
                />
              </td>
              <td className="border border-gray-300 py-2 px-4 text-center">
                <button
                  onClick={() => handleDeleteRow(row.id)}
                  className="text-red-600 hover:text-red-800 transition duration-300 ease-in-out"
                >
                  <MdDeleteForever size={24} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DashboardProducts;
