import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { BaseUrl } from '../Config/config';

const Address = () => {
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    country: '',
    postal_code: '',
    is_billing: false,
    is_shipping: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [showForm, setShowForm] = useState(false);

  // Fetch user addresses on component mount
  useEffect(() => {
    toast.dismiss();

    const fetchAddresses = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('You are not logged in!');
        return;
      }

      try {
        const response = await fetch(BaseUrl + 'api/addresses', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          toast.error('Session expired! Please log in again.');
          localStorage.removeItem('token');
          // Optionally redirect to login page
          return;
        }

        if (!response.ok) {
          throw new Error('Failed to fetch addresses');
        }

        const data = await response.json();
        setAddresses(data);
      } catch (error) {
        console.error('Error fetching addresses:', error);
        toast.error('Failed to fetch addresses');
      }
    };

    fetchAddresses();
  }, []);

  // Handle delete address
  const handleDelete = async (addressId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You are not logged in!');
      return;
    }

    try {
      const response = await fetch(BaseUrl + `api/addresses/${addressId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Session expired! Please log in again.');
        localStorage.removeItem('token');
        // Optionally redirect to login page
        return;
      }

      if (!response.ok) {
        toast.error('Failed to delete address');
        return;
      }

      setAddresses((prevAddresses) =>
        prevAddresses.filter((address) => address.id !== addressId)
      );
      toast.success('Address deleted successfully');
    } catch (error) {
      console.error('Error deleting address:', error);
      toast.error('Failed to delete address');
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Add or update address
  const handleAddOrUpdateAddress = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You are not logged in!');
      return;
    }

    // Simple validation check
    if (!newAddress.street || !newAddress.city || !newAddress.state || !newAddress.country || !newAddress.postal_code) {
      toast.error('Please fill in all fields');
      return;
    }

    try {
      const method = isEditing ? 'PUT' : 'POST'; // Changed method for updating
      const endpoint = isEditing
        ? BaseUrl + `api/addresses/${editingAddressId}`
        : BaseUrl + 'api/addresses';

      const response = await fetch(endpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newAddress),
      });

      if (response.status === 401) {
        toast.error('Session expired! Please log in again.');
        localStorage.removeItem('token');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to add/update address');
      }

      const data = await response.json();
      if (isEditing) {
        setAddresses((prevAddresses) =>
          prevAddresses.map((address) => (address.id === data.id ? data : address))
        );
        toast.success('Address updated successfully');
      } else {
        setAddresses((prevAddresses) => [data, ...prevAddresses]);
        toast.success('Address added successfully');
      }

      // Reset form
      setNewAddress({
        street: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        is_billing: false,
        is_shipping: false,
      });
      setIsEditing(false);
      setShowForm(false); // Hide form on successful submission
    } catch (error) {
      console.error('Error adding/updating address:', error);
      toast.error('You can only add three addresses. Delete one to add a new address.');
    }
  };

  // Edit address
  const handleEditAddress = (address) => {
    setNewAddress({
      street: address.street,
      city: address.city,
      state: address.state,
      country: address.country,
      postal_code: address.postal_code,
      is_billing: address.is_billing,
      is_shipping: address.is_shipping,
    });
    setEditingAddressId(address.id);
    setIsEditing(true);
    setShowForm(true);
  };

  return (
    <div className="container mx-auto p-6 bg-gradient-to-br from-green-200 via-green-400 to-green-600 min-h-screen">
      <h1 className="text-3xl font-bold mb-8 text-center text-white">Address Management</h1>

      <button
        onClick={() => {
          setShowForm(true);
          setIsEditing(false); // Reset form to add mode
        }}
        className="bg-green-700 text-white font-bold py-2 px-4 rounded-md mb-6 hover:bg-green-800"
      >
        Add New Address
      </button>

      {showForm ? (
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-center text-green-700">
            {isEditing ? 'Edit Address' : 'Add New Address'}
          </h2>
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
            {[ 
              { name: 'street', label: 'Street', type: 'text' },
              { name: 'city', label: 'City', type: 'text' },
              { name: 'state', label: 'State', type: 'text' },
              { name: 'country', label: 'Country', type: 'text' },
              { name: 'postal_code', label: 'Postal Code', type: 'text' },
            ].map(({ name, label, type }) => (
              <div key={name}>
                <label className="block text-gray-700 text-sm font-medium mb-1" htmlFor={name}>
                  {label}
                </label>
                <input
                  type={type}
                  name={name}
                  id={name}
                  value={newAddress[name]}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            ))}
            {/* Checkboxes for is_billing and is_shipping */}
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-1">
                Is Billing Address
              </label>
              <input
                type="checkbox"
                name="is_billing"
                checked={newAddress.is_billing}
                onChange={handleInputChange}
                className="h-5 w-5 text-blue-600"
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-1">
                Is Shipping Address
              </label>
              <input
                type="checkbox"
                name="is_shipping"
                checked={newAddress.is_shipping}
                onChange={handleInputChange}
                className="h-5 w-5 text-blue-600"
              />
            </div>
          </div>
          <button
            onClick={handleAddOrUpdateAddress}
            className="bg-green-700 text-white font-bold py-2 px-4 rounded-md mt-6 hover:bg-green-800"
          >
            {isEditing ? 'Update Address' : 'Add Address'}
          </button>
        </div>
      ) : (
        <div>
          {addresses.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {addresses.map((address, index) => (
                <div key={address.id || index} className="bg-white shadow-lg rounded-lg p-6">
                  <h3 className="text-lg font-semibold mb-2">{address.street}</h3>
                  <p className="text-gray-700 mb-2">
                    {address.city}, {address.state}, {address.country}, {address.postal_code}
                  </p>
                  <p className="text-gray-700 mb-2">
                    Billing: {address.is_billing ? 'Yes' : 'No'}, Shipping: {address.is_shipping ? 'Yes' : 'No'}
                  </p>
                  <button
                    onClick={() => handleEditAddress(address)}
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded-md mr-4 hover:bg-blue-600"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(address.id)}
                    className="bg-red-500 text-white font-bold py-2 px-4 rounded-md hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-white">No addresses available.</p>
          )}
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default Address;
