import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Titles from '../Titles';
import { BsGridFill } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import CategoryProductsModal from './CategoryProductsModal';
import { BaseUrl } from '../../Config/config';

const CategoriesShop = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch categories
        const categoriesResponse = await axios.get(BaseUrl + 'api/Master/GetItemMaster?type=0');

        // Fetch all products
        const productsResponse = await axios.get(BaseUrl + 'api/Master/Get_All_Items');

        setCategories(categoriesResponse.data);
        setProducts(productsResponse.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    const categoryProducts = products.filter(product => product.ItemType === category.ItemType && product.Status === 0);
    setCategoryProducts(categoryProducts);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCategory(null);
    setModalOpen(false);
  };

  if (loading) {
    return <p className="text-center text-lg">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-500">Error: {error.message}</p>;
  }

  // Filter categories based on status
  const filteredCategories = categories.filter(category => category.Status === 0);

  const getSlidesPerView = () => {
    const categoryCount = filteredCategories.length;
    if (categoryCount <= 2) return 2;
    if (categoryCount <= 4) return 3;
    return 4; // Updated for better responsiveness
  };

  // Gradient border style
  const gradientBorderStyle = {
    borderImage: 'linear-gradient(45deg, #facc15, #10b981)',
    borderImageSlice: 1,
    borderWidth: '4px',
    borderStyle: 'solid'
  };

  return (
    <div className="container mx-auto px-4 py-6 sm:px-6 lg:px-8">
      <Titles
        title={selectedCategory ? selectedCategory.ItemType : "Categories"}
        Icon={BsGridFill}
      />
      <div className="mt-4 sm:mt-6 lg:mt-8 flex flex-col items-center">
        {selectedCategory ? (
          <button
            onClick={handleCloseModal}
            className="bg-[#08830a] transition duration-300 ease-in-out py-2 px-4 sm:py-3 sm:px-6 font-semibold text-xs sm:text-sm text-white shadow-lg rounded-md"
          >
            Back to Categories
          </button>
        ) : (
          <Swiper
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
            slidesPerView={getSlidesPerView()}
            spaceBetween={20}
            breakpoints={{
              640: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              1024: { slidesPerView: getSlidesPerView() },
            }}
            className="py-4 sm:py-6 lg:py-8 w-full"
          >
            {filteredCategories.map((category) => (
              <SwiperSlide
                key={category.Id}
                onClick={() => handleCategoryClick(category)}
                className="cursor-pointer flex justify-center items-center"
              >
                <div
                  style={gradientBorderStyle}
                  className="flex flex-col items-center justify-center w-28 h-28 sm:w-36 sm:h-36 bg-white shadow-lg transition duration-500 ease-in-out rounded-md"
                >
                  <BsGridFill className="text-xl sm:text-3xl text-[#08830a]" />
                  <h3 className="mt-2 text-xs sm:text-sm font-bold text-[#08830a]">
                    {category.ItemType}
                  </h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      {modalOpen && (
        <CategoryProductsModal
          modalOpen={modalOpen}
          onClose={handleCloseModal}
          products={categoryProducts}
        />
      )}
    </div>
  );
};

export default CategoriesShop;
  