import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { BaseUrl } from "../../../Config/config";

function AllProductShow() {
  const maxItemsPerPage = 10;
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedItemType, setSelectedItemType] = useState("");
  const [showItems, setShowItems] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayCount, setDisplayCount] = useState(maxItemsPerPage);

  const [editValues, setEditValues] = useState({
    Id: "",
    item_name: "",
    item_description: "",
    gst: "",
    Cess: 0,
    hsn_code: "",
    BatchNum: 0,
    bar_code: "",
    CAT_Number: 0,
    item_code: "",
    serial_number: "",
    manufacture_date: "",
    expiry_date: "",
    rate: "",
    unit_id: "",
    item_type_id: "",
    warranty_period: "",
    model: "",
    image: "",
  });
  const [itemTypes, setItemTypes] = useState([]);

  useEffect(() => {
    fetchItems();
    fetchItemTypes();
  }, []);

  const fetchItems = async () => {
    const token = localStorage.getItem("tokenadmin");
    try {
      const response = await fetch(`${BaseUrl}api/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch items");
      }
      const data = await response.json();
      const updatedData = data.products.map((item, index) => ({
        ...item,
        SequentialId: index,
        ItemImageUrl: item.ItemImage
          ? `${BaseUrl}api/products/LoadItemImage?ImageName=${item.ItemImage}`
          : null,
      }));
      setItems(updatedData);
      const initialShowItems = {};
      updatedData.forEach((item) => {
        initialShowItems[item.Id] = item.Status === 2;
      });
      setShowItems(initialShowItems);
    } catch (error) {
      console.error("Error fetching items:", error);
      toast.error("Failed to fetch items");
    }
  };

  const fetchItemTypes = async () => {
    const token = localStorage.getItem("tokenadmin");
    try {
      const response = await fetch(`${BaseUrl}api/item-types`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch item types");
      }
      const data = await response.json();
      setItemTypes(data);
    } catch (error) {
      console.error("Error fetching item types:", error);
      toast.error("Failed to fetch item types");
    }
  };

  const toggleItem = async (itemId) => {
    console.log("itemId",itemId);
    
    const newStatus = showItems[itemId] ? 0 : 2; // Toggle status between 0 and 2
    const token = localStorage.getItem("tokenadmin");

    try {
      const response = await fetch(
        `${BaseUrl}api/products{itemId}&Status=${newStatus}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update item status");
      }

      setShowItems((prevState) => ({
        ...prevState,
        [itemId]: newStatus === 2,
      }));
      
      setChangesMade(true);
      toast.success("Item status updated successfully");
    } catch (error) {
      console.error("Error updating item status:", error);
      toast.error("Failed to update item status");
    }
  };

  const handleDelete = async (itemId) => {
    const token = localStorage.getItem("tokenadmin");
    try {
      const response = await fetch(`${BaseUrl}api/products/Delete_Item?itemId=${itemId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        setItems((prevItems) => prevItems.filter((item) => item.Id !== itemId));
        toast.success("Item deleted successfully");
      } else {
        const errorData = await response.json();
        toast.error(`Failed to delete item: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Failed to delete item");
    }
  };

  const handleEdit = (itemId) => {
    const item = items.find((item) => item.Id === itemId);
    if (!item) {
      toast.error("Item not found");
      return;
    }
    setEditItem(itemId);
    setEditValues({
      Id: item.Id || "",
      item_name: item.item_name || "",
      item_description: item.item_description || "",
      gst: item.gst || "",
      Cess: item.Cess || 0,
      hsn_code: item.hsn_code || "",
      BatchNum: item.BatchNum || 0,
      bar_code: item.bar_code || "",
      CAT_Number: item.CAT_Number || 0,
      item_code: item.item_code || "",
      serial_number: item.serial_number || "",
      manufacture_date: item.manufacture_date || "",
      expiry_date: item.expiry_date || "",
      rate: item.rate || "",
      unit_id: item.unit_id || "",
      item_type_id: item.item_type_id || "",
      warranty_period: item.warranty_period || "",
      model: item.model || "",
      image: item.image || "",
    });
    setSelectedItemType(item.item_type_id || "");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return; // Check if file is selected

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      setEditValues((prevValues) => ({
        ...prevValues,
        image: base64String,
      }));
    };

    reader.readAsDataURL(file);
  };

  const handleSave = async () => {
    const updatedEditValues = {
      item_name: editValues.item_name,
      item_description: editValues.item_description,
      gst: editValues.gst,
      hsn_code: editValues.hsn_code,
      bar_code: editValues.bar_code,
      item_code: editValues.item_code,
      serial_number: editValues.serial_number,
      manufacture_date: editValues.manufacture_date,
      expiry_date: editValues.expiry_date,
      rate: editValues.rate,
      unit_id: editValues.unit_id,
      item_type_id: selectedItemType,
      warranty_period: editValues.warranty_period,
      image: editValues.image ? `data:image/jpeg;base64,${editValues.image}` : "",
    };

    try {
      const token = localStorage.getItem("tokenadmin");
      const response = await fetch(
        `${BaseUrl}api/products/Update_Item`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ...updatedEditValues, Id: editItem }),
        }
      );

      if (response.ok) {
        const updatedItem = await response.json();
        setItems((prevItems) =>
          prevItems.map((item) => (item.Id === updatedItem.Id ? updatedItem : item))
        );
        setEditItem(null);
        toast.success("Item saved successfully");
      } else {
        const errorData = await response.json();
        toast.error(`Failed to save item: ${errorData.errors?.[0] || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error saving item:", error);
      toast.error("Failed to save item");
    }
  };

  const handleCancelEdit = () => {
    setEditItem(null);
    setEditValues({
      Id: "",
      item_name: "",
      item_description: "",
      gst: "",
      Cess: 0,
      hsn_code: "",
      BatchNum: 0,
      bar_code: "",
      CAT_Number: 0,
      item_code: "",
      serial_number: "",
      manufacture_date: "",
      expiry_date: "",
      rate: "",
      unit_id: "",
      item_type_id: "",
      warranty_period: "",
      model: "",
      image: "",
    });
    setSelectedItemType("");
  };

  const handleShowMore = () => {
    setDisplayCount((prevCount) => prevCount + 10);
  };

  return (
    <div className="p-6 bg-navy-900 min-h-screen">
      <ToastContainer />
      <h1 className="text-3xl font-semibold mb-6 text-center text-green">All Products</h1>
      <div className="overflow-x-auto">
        <table className="w-full border border-gray-300">
          <thead>
            <tr>
              <th className="p-2 border-b">Image</th>
              <th className="p-2 border-b">Name</th>
              <th className="p-2 border-b">Description</th>
              <th className="p-2 border-b">Price</th>
              <th className="p-2 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.slice(0, displayCount).map((item) => (
              <tr key={item.Id}>
                <td className="p-2 border-b">
                  {item.ItemImageUrl ? (
                    <img src={item.ItemImageUrl} alt={item.item_name} className="w-16 h-16 object-cover" />
                  ) : (
                    'No Image'
                  )}
                </td>
                <td className="p-2 border-b">{item.item_name}</td>
                <td className="p-2 border-b">{item.item_description}</td>
                <td className="p-2 border-b">{item.rate}</td>
                <td className="p-2 border-b flex space-x-2">
                  <button onClick={() => handleEdit(item.Id)} className="text-blue-500">
                    <MdEdit size={20} />
                  </button>
                  <button onClick={() => handleDelete(item.Id)} className="text-red-500">
                    <MdDeleteForever size={20} />
                  </button>
                  <label className="flex items-center space-x-2">
                    <span>Active</span>
                    <Switch
                      checked={showItems[item.Id] || false}
                      onChange={() => toggleItem(item.Id)}
                    />
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {displayCount < items.length && (
        <button
          onClick={handleShowMore}
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded">
          Show More
        </button>
      )}
      {editItem && (
        <div className="mt-6 p-4 border border-gray-300 bg-white">
          <h2 className="text-xl font-semibold mb-4">Edit Product</h2>
          <form>
            <label className="block mb-2">
              Name:
              <input
                type="text"
                name="item_name"
                value={editValues.item_name}
                onChange={handleChange}
                className="block w-full mt-1 border border-gray-300 p-2 rounded"
              />
            </label>
            <label className="block mb-2">
              Description:
              <input
                type="text"
                name="item_description"
                value={editValues.item_description}
                onChange={handleChange}
                className="block w-full mt-1 border border-gray-300 p-2 rounded"
              />
            </label>
            {/* Add other form fields here */}
            <label className="block mb-2">
              Image:
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="block mt-1"
              />
            </label>
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={handleSave}
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleCancelEdit}
                className="px-4 py-2 bg-gray-500 text-white rounded"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default AllProductShow;
