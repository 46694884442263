import React, { useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../Config/config';

function BannerSettings() {
    const [bannerName, setBannerName] = useState('');
    const [status, setStatus] = useState('0');
    const [image, setImage] = useState(null);

    const handleNameChange = (e) => setBannerName(e.target.value);
    const handleStatusChange = (e) => setStatus(e.target.value);
    const handleImageChange = (e) => setImage(e.target.files[0]);
    const token = localStorage.getItem('token');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('banner_name', bannerName);
        formData.append('status', status);
        if (image) formData.append('image', image);

        try {
            // const token = 'YOUR_ADMIN_TOKEN_HERE'; 
            await axios.post(BaseUrl+'api/banners', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });
            alert('Banner updated successfully!');
        } catch (error) {
            console.error('Error updating banner:', error);
            alert('Failed to update banner.');
        }
    };

    return (
        <div className="min-h-screen bg-green-900 py-12 px-6 flex items-center justify-center relative">
            <div className="container mx-auto max-w-2xl w-3/4">
                <div className="bg-white rounded-lg shadow-lg p-8 w-full animate-fade-in-up">
                    <h1 className="text-4xl font-extrabold text-center mb-6 bg-gradient-to-r from-[#004a00] to-[#4ade80] bg-clip-text text-transparent uppercase">
                        Banner Settings
                    </h1>
                    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                        <div className="flex flex-col mb-4">
                            <label htmlFor="bannerName" className="text-lg font-medium">Banner Name:</label>
                            <input
                                type="text"
                                id="bannerName"
                                value={bannerName}
                                onChange={handleNameChange}
                                className="mt-2 p-2 border rounded"
                            />
                        </div>
                        <div className="flex flex-col mb-4">
                            <label htmlFor="status" className="text-lg font-medium">Status:</label>
                            <select
                                id="status"
                                value={status}
                                onChange={handleStatusChange}
                                className="mt-2 p-2 border rounded"
                            >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                        <div className="flex flex-col mb-4">
                            <label htmlFor="image" className="text-lg font-medium">Banner Image:</label>
                            <input
                                type="file"
                                id="image"
                                onChange={handleImageChange}
                                className="mt-2"
                            />
                        </div>
                        <button type="submit" className="px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 transition transform hover:scale-105">
                            Submit
                        </button>
                    </form>
                </div>
                {/* Decorative Shapes */}
                <div className="absolute inset-0 pointer-events-none overflow-hidden">
                    <svg className="absolute top-0 left-0 transform translate-x-16 -translate-y-20" width="300" height="300" fill="none">
                        <circle cx="150" cy="150" r="150" fill="#A2CA71" fillOpacity="0.2" />
                    </svg>
                    <svg className="absolute bottom-0 right-0 transform -translate-x-20 translate-y-24" width="400" height="400" fill="none">
                        <circle cx="200" cy="200" r="200" fill="#387F39" fillOpacity="0.15" />
                    </svg>
                </div>
            </div>
            {/* Custom CSS for animations */}
            <style jsx>{`
                @keyframes fade-in-up {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .animate-fade-in-up {
                    animation: fade-in-up 0.5s ease-in-out forwards;
                }
            `}</style>
        </div>
    );
}

export default BannerSettings;
