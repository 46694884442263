import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../Config/config';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { toast, ToastContainer } from 'react-toastify';
import Login from './Login';
import OtpModal from './OtpModal'; // Ensure OtpModal is imported
import 'react-toastify/dist/ReactToastify.css';

const SignIn = () => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    UserName: '',
    Password: '',
    Mobile: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false); // State for OTP modal
  const [emailForOtp, setEmailForOtp] = useState(''); // State to store email for OTP
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      name: formData.Name ?? '',
      email: formData.Email ?? '',
      password: formData.Password ?? '',
      role: 'user',
    };

    try {
      const response = await axios.post(`${BaseUrl}api/register`, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      console.log('response => ', response);

      if (response.status === 201) { // Check for status code 201
        setEmailForOtp(formData.Email); // Store the email
        setOtpModalOpen(true); // Open OTP modal
      } else {
        toast.error('Registration failed', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
        });
      }

    } catch (error) {
      toast.error(error.response?.data?.message || 'Registration failed', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
      });
      console.error('error caught: ', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'Mobile') {
      const numericValue = value.replace(/\D/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const containerStyle = {
    backgroundColor: 'transparent',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3rem 1rem',
    minHeight: '100vh',
    position: 'relative',
  };

  const formContainerStyle = {
    backgroundColor: '#ffffff',
    color: '#000000',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
    position: 'relative',
  };

  const inputStyle = {
    backgroundColor: '#ffffff',
    color: '#000000',
    border: '1px solid #cccccc',
    padding: '0.75rem',
    borderRadius: '4px',
    width: '100%',
    marginBottom: '1rem',
  };

  const buttonStyle = {
    background: 'linear-gradient(to right, #004d00, #00ff00)',
    color: '#ffffff',
    padding: '0.75rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
    fontSize: '1rem',
    marginTop: '1rem',
  };

  const headingStyle = {
    color: '#000000',
    marginBottom: '1rem',
    textAlign: 'center',
  };

  const passwordToggleStyle = {
    position: 'absolute',
    right: '1rem',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <div style={formContainerStyle}>
        <div>
          <h2 style={headingStyle}>Sign up to your account</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name *</label>
            <input
              id="name"
              name="Name"
              type="text"
              required
              value={formData.Name}
              onChange={handleChange}
              style={inputStyle}
            />
          </div>
          <div>
            <label htmlFor="email-address">Email address *</label>
            <input
              id="email-address"
              name="Email"
              type="email"
              required
              value={formData.Email}
              onChange={handleChange}
              style={inputStyle}
            />
          </div>
          <div>
            <label htmlFor="password">Password *</label>
            <div style={{ position: 'relative' }}>
              <input
                id="password"
                name="Password"
                type={showPassword ? 'text' : 'password'}
                required
                value={formData.Password}
                onChange={handleChange}
                style={inputStyle}
              />
              <span
                onClick={togglePasswordVisibility}
                style={passwordToggleStyle}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </span>
            </div>
          </div>
          <button type="submit" style={buttonStyle}>Register</button>
          <p className="text-center mt-2">
            Do you have an account?{' '}
            <span onClick={() => setModalOpen(!modalOpen)} className="text-blue-500 cursor-pointer">
              Login
            </span>
          </p>
        </form>
      </div>
      <ToastContainer />
      <Login modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <OtpModal isOpen={otpModalOpen} onClose={() => setOtpModalOpen(false)} email={emailForOtp} />
    </div>
  );
};

export default SignIn;
