import React, { useEffect, useState } from 'react';
import { FaShoppingBag, FaRupeeSign } from 'react-icons/fa';
import ProductModal1 from '../Modals/ProductModal1';
import { BaseUrl } from '../../Config/config';

const PopularProducts = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showItems, setShowItems] = useState({});

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await fetch(`${BaseUrl}api/Master/Get_All_Items`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch items');
                }
                const data = await response.json();
                const updatedData = data.map((item, index) => ({
                    ...item,
                    SequentialId: index,
                    ItemImageUrl: item.ItemImage ? `${BaseUrl}api/Master/LoadItemImage?ImageName=${item.ItemImage}` : null
                }));
                setItems(updatedData);
                const initialShowItems = {};
                updatedData.forEach(item => {
                    initialShowItems[item.Id] = item.Status === 1;
                });
                setShowItems(initialShowItems);
            } catch (error) {
                setError(error);
                console.error('Error fetching items:', error);
            } finally {
                setLoading(false); // Ensure loading is set to false after data fetch
            }
        };

        fetchItems();
    }, []);

    const handleAddToCart = (productId) => {
        // Placeholder function for adding to cart
        console.log(`Added item with ID ${productId} to cart`);
    };

    const openModal = (product) => {
        setSelectedProduct(product);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    if (loading) {
        return <p className="text-center text-lg">Loading...</p>;
    }

    if (error) {
        return <p className="text-center text-lg text-red-500">Error: {error.message}</p>;
    }

    const filteredItems = items.filter(item => item.Status === 0);

    return (
        <div className="shop-items mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6">
            <h2 className="text-3xl font-bold mb-6 text-center text-gray-900">Popular Products</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                {filteredItems.slice(0, 8).map(item => ( // Limiting the number of displayed items to 8
                    <div
                        key={item.Id} // Use item.Id as the key
                        className="bg-white text-black shadow-md rounded-lg overflow-hidden transition-transform duration-300 hover:shadow-lg hover:scale-105"
                        onClick={() => openModal(item)}
                    >
                        <div className="relative cursor-pointer w-full h-64 bg-gray-100">
                            <img
                                src={item.ItemImageUrl} // Use item.ItemImageUrl for image source
                                alt={item.ItemName}
                                className="w-full h-full object-cover rounded-t-lg"
                            />
                        </div>
                        <div className="p-4">
                            <h3 className="text-lg font-semibold text-gray-800">{item.ItemName}</h3>
                            <div className="flex items-center mt-2">
                                <FaRupeeSign className="text-gray-800" />
                                <span className="text-lg font-bold text-gray-800 ml-1">{item.Rate}</span>
                            </div>
                            <button
                                className="mt-4 bg-green-600 text-white rounded-md px-4 py-2 flex items-center transition-colors duration-300 hover:bg-green-700"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleAddToCart(item.Id); // Use item.Id for the productId
                                }}
                            >
                                <FaShoppingBag className="mr-2" />
                                Add to Cart
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* ProductModal component */}
            {selectedProduct && (
                <ProductModal1
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    product={selectedProduct}
                    onClose={closeModal}
                />
            )}
        </div>
    );
};

export default PopularProducts;
